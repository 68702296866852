<template>
  <div>
    <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
      <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
        <el-form-item label="岗位名称" prop="name">
          <el-input v-model="formData.name" clearable maxlength="60" placeholder="请输入岗位名称" />
        </el-form-item>
        <el-form-item label="岗位地点" prop="place">
          <el-input v-model="formData.place" clearable maxlength="60" placeholder="请输入岗位地点" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email" clearable maxlength="60" placeholder="请输入岗位邮箱" />
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input v-model="formData.describe" type="textarea" :rows="6" clearable placeholder="请输岗位描述" />
        </el-form-item>
        <el-form-item label="要求" prop="require">
          <el-input v-model="formData.require" type="textarea" :rows="6" clearable placeholder="请输岗位要求" />
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用">></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding-left: 140px">
        <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
        <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {
        describeList: [{ text: '' }],
        requireList: [{ text: '' }]
      }, //表单数据
      typeList: [],
      rules: {
        name: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
        place: [{ required: true, message: '请输入岗位地点', trigger: 'blur' }],
        email: [{ required: true, message: '请输入岗位邮箱', trigger: 'blur' }],
        theme: [{ required: true, message: '请输入岗位主题', trigger: 'blur' }],
        status: [{ required: true }]
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改招聘' : '添加招聘'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            status: 1,
            describeList: [{ text: '' }],
            requireList: [{ text: '' }]
          }
        }
      } else {
        this.formData = {
          describeList: [{ text: '' }],
          requireList: [{ text: '' }]
        }
      }
    },
    formData(val) {}
  },

  created() {},
  methods: {
    // 增加输入
    addItem(value) {
      if (value == 'describe') {
        this.formData.describeList.push({ text: '' })
      } else {
        this.formData.requireList.push({ text: '' })
      }
    },
    // 减少输入
    deleteItem(index, value) {
      if (value == 'describe') {
        this.formData.describeList.splice(index, 1)
      } else {
        this.formData.requireList.splice(index, 1)
      }
    },
    //提交
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const response = await http.POST('/jw-admin', {
          module: 'advertise',
          operation: 'setAdvertise',
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
